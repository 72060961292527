<template>
  <div class="page">
    <div class="page__header">
      <h1 style="margin-top: 10px">Collection</h1>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <IconButton
          class="button--auto button--uppercase"
          text="new Viewing Room"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <ViewingRoom
      v-if="modal"
      :modal="modal"
      :item="viewingRoom"
      @hideModal="modal = false"
      @createViewingRoom="createViewingRoom"
      @updateViewingRoom="updateViewingRoom"
      @removeViewingRoom="confirmeRemoveViewingRoom"
    ></ViewingRoom>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import Table from "@/components/Table.vue";
import ViewingRoom from "./ViewingRoom.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    HorisontalTabsList,
    Table,
    ViewingRoom,
    IconButton,
    IconPlusBold,
  },
  data() {
    return {
      tabs: [
        {
          title: "Artwork",
          path: "/admin/collection/artworks",
          key: "artwork",
        },
        {
          title: "Creators",
          path: "/admin/collection/creators",
          key: "artists",
        },
        {
          title: "Categories",
          path: "/admin/collection/categories",
          key: "categories",
        },
        {
          title: "Viewing Rooms",
          path: "/admin/collection/viewing-rooms",
          key: "viewing-rooms",
        },
      ],
      modal: false,
      tableData: {
        page: 1,
        sort: "name",
        order: "asc",
        status: "",
      },
    };
  },
  async created() {
    await this.apiGetViewingRooms(this.tableData);
    this.initialViewingRoom();
  },
  computed: {
    ...mapGetters("viewingRooms", {
      items: "getViewingRooms",
      headerItems: "getViewingRoomsHeaders",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      viewingRoom: "getViewingRoom",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialViewingRoom();
        // this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage"]),
    ...mapActions("viewingRooms", [
      "apiGetViewingRooms",
      "apiGetViewingRoom",
      "apiPostViewingRoom",
      "apiPutViewingRoom",
      "apiDeleteViewingRoom",
    ]),
    ...mapMutations("viewingRooms", ["initialViewingRoom"]),
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetViewingRooms(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetViewingRooms(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetViewingRoom(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmeRemoveViewingRoom(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "view": {
          window.open(`/viewing-room/${item.slug}`, "_blank");
          //this.$router.push({ path: `/viewing-room/${item.slug}` });
          break;
        }
      }
    },
    async createViewingRoom({ data }) {
      let result = await this.apiPostViewingRoom(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetViewingRooms(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Viewing Room successfully created",
          confirmButtonText: "Ok",
        });
      }
    },
    async updateViewingRoom({ data }) {
      let result = await this.apiPutViewingRoom(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetViewingRooms(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Viewing Room successfully updated",
          confirmButtonText: "Ok",
        });
      }
    },
    confirmeRemoveViewingRoom(item) {
      this.$swal({
        title: `Delete ${item.name}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeViewingRoom(item.id);
        }
      });
    },
    async removeViewingRoom(id) {
      let result = await this.apiDeleteViewingRoom(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetViewingRooms(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    min-height: 7rem;
  }
}
</style>

