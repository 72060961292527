<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      ref="observer"
      tag="div"
      class="viewing-room"
      id="scroll-block"
    >
      <header class="viewing-room__header">
        <h3 class="viewing-room__header_title">
          {{ viewingRoom.id ? "Edit" : "Add" }} Viewing Room
        </h3>
        <div class="viewing-room__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete"
            @click="$emit('removeViewingRoom', viewingRoom)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid"
            @click="saveViewingRoom"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Required Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Room Name"
              :required="true"
              :validateError="errors[0]"
              v-model="viewingRoom.name"
              :element="viewingRoom.name"
              @input="viewingRoom.name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0]"
              v-model="viewingRoom.slug"
              :element="viewingRoom.slug"
              @input="viewingRoom.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example: {{ `${url}/viewing-room/${viewingRoom.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Description"
            v-model="viewingRoom.description"
            :element="viewingRoom.description"
            :validateError="errors[0]"
            @input="viewingRoom.description = $event"
          ></BaseTextarea>
        </ValidationProvider>
      </section>
      <section class="form">
        <h3 class="form__header">Display Options</h3>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Room Name"
              v-model="viewingRoom.show_room_name"
              :selected="+viewingRoom.show_room_name"
              @input="viewingRoom.show_room_name = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Room Description"
              v-model="viewingRoom.show_room_description"
              :selected="+viewingRoom.show_room_description"
              @input="viewingRoom.show_room_description = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Artwork Names"
              v-model="viewingRoom.show_artwork_names"
              :selected="+viewingRoom.show_artwork_names"
              @input="viewingRoom.show_artwork_names = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Creator Names"
              v-model="viewingRoom.show_artist_names"
              :selected="+viewingRoom.show_artist_names"
              @input="viewingRoom.show_artist_names = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Artwork Categories"
              v-model="viewingRoom.show_artwork_categories"
              :selected="+viewingRoom.show_artwork_categories"
              @input="viewingRoom.show_artwork_categories = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Artwork Details"
              v-model="viewingRoom.show_artwork_details"
              :selected="+viewingRoom.show_artwork_details"
              @input="viewingRoom.show_artwork_details = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <SimpleToggle
              placeholder="Artwork Attributes"
              v-model="viewingRoom.show_artwork_attributes"
              :selected="+viewingRoom.show_artwork_attributes"
              @input="viewingRoom.show_artwork_attributes = $event ? true : false"
            ></SimpleToggle>
          </div>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Artwork</h3>
        <div class="form__row">
          <QuickSearchInput
            id="quick-search-artworks"
            :focusOpen="true"
            :matches="quickSearchArtworks"
            placeholder="Quick Search"
            @selectItem="selectQuickSearchArtworks"
            @searchMatches="apiGetQuickSearchArtworks({ id: viewingRoom.id, ...$event })"
          ></QuickSearchInput>
        </div>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-artworks"
            :creating="false"
            :focusOpen="true"
            :image="true"
            :loading="artworksLoading"
            :items="viewingRoom.artworks"
            :matches="matchesArtworks"
            placeholder="Artwork Search"
            @selectItem="viewingRoom.artworks = $event"
            @reorder="viewingRoom.artworks = $event"
            @searchMatches="apiGetMatchedArtworks"
          ></MultipleAutocompleteInput>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import UploadImage from "@/components/UploadImage.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import QuickSearchInput from "@/components/inputs/QuickSearchInput.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    UploadImage,
    BaseButton,
    BaseInput,
    MultipleAutocompleteInput,
    QuickSearchInput,
    SimpleToggle,
    BaseTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      viewingRoom: {
        name: "",
        slug: "",
        description: "",
        show_room_name: false,
        show_room_description: false,
        show_artwork_names: false,
        show_artist_names: false,
        show_artwork_categories: false,
        show_artwork_details: false,
        show_artwork_attributes: false,
        artworks: [],
      },
      changed: false,
      loadingImage: false,
    };
  },
  created() {
    if (this.item != null) {
      this.viewingRoom = JSON.parse(JSON.stringify(this.item));
    }
  },
  computed: {
    ...mapGetters("categories", {
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("artworks", {
      matchesArtworks: "getMatchedArtworks",
      artworksLoading: "getChangesLoading",
    }),
    ...mapGetters("viewingRooms", {
      quickSearchArtworks: "getQuickSearchArtworks",
    }),
  },
  watch: {
    viewingRoom: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.name != "") {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapActions("artworks", ["apiGetMatchedArtworks"]),
    ...mapActions("viewingRooms", ["apiGetQuickSearchArtworks"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.saveViewingRoom() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveViewingRoom() {
      let data = {
        ...this.viewingRoom,
        artworks: this.viewingRoom.artworks.map(el => {
          return {
            id: el.id,
            order: el.order,
          };
        }),
      };
      if (this.viewingRoom.id) {
        this.$emit("updateViewingRoom", { data: data });
      } else {
        this.$emit("createViewingRoom", { data: data });
      }
    },
    selectQuickSearchArtworks(value) {
      value.forEach(item => {
        item.artworks.forEach(quickSearchArtwork => {
          let artworkInclude = false;
          this.viewingRoom.artworks.forEach(artwork => {
            if (artwork.id === quickSearchArtwork.id) artworkInclude = true;
          });
          if (!artworkInclude)
            this.viewingRoom.artworks.push({
              ...quickSearchArtwork,
              order: this.viewingRoom.artworks.length,
            });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.viewing-room {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
